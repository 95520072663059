import React, { useState, useEffect } from 'react';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import { motion, AnimatePresence, useAnimation } from 'motion/react';
import hallsLogo from './halls-logo.png.avif';
import './NavBar.css';

const path1Variants = {
    open: { d: 'M3 3L21 21' },
    closed: { d: 'M0 6L24 6' }
};

const path2Variants = {
    open: { d: 'M0 12L24 12', opacity: 0 },
    closed: { d: 'M0 12L24 12', opacity: 1 }
};

const path3Variants = {
    open: { d: 'M3 21L21 3' },
    closed: { d: 'M0 18L24 18' }
};

const NavBar = ({
    hidden,
    onHomeClick,
    onAboutClick,
    onPartnersClick,
    onTestimonialsClick,
    onServicesClick,
    onContactClick
}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const path1Controls = useAnimation();
    const path2Controls = useAnimation();
    const path3Controls = useAnimation();

    const screenWidth = useScreenWidth();

    useEffect(() => {
        if (menuOpen) {
            path1Controls.start(path1Variants.open);
            path2Controls.start(path2Variants.open);
            path3Controls.start(path3Variants.open);
        } else {
            path1Controls.start(path1Variants.closed);
            path2Controls.start(path2Variants.closed);
            path3Controls.start(path3Variants.closed);
        }
    }, [menuOpen, path1Controls, path2Controls, path3Controls]);

    useEffect(() => {
        if ((screenWidth !== 'xs' && screenWidth !== 'sm') && menuOpen) {
            setMenuOpen(false);
        }
    }, [screenWidth, menuOpen/*, setMenuOpen*/]);

    return (
        <React.Fragment>
            <motion.header
                initial="hidden"
                variants={{
                    visible: { y: 0/*, opacity: 1*/ },
                    hidden: { y: "-100%"/*, opacity: 1*/ }
                }}
                animate={hidden ? "hidden" : "visible"}
                transition={{ duration: 0.35, ease: "easeInOut" }}
                onAnimationStart={latest => {
                    if (latest === 'hidden') {
                        if (menuOpen) {
                            setMenuOpen(false);
                        }
                    }
                }}
                className="NavBar fixed w-full"
            >
                <div className="NavBar-inner border border-gray-400 backdrop-filter backdrop-blur-lg top-0 w-full shadow-lg rounded-md">
                    <nav className="container mx-auto px-6">
                        <div className="flex justify-between items-center">
                            <button className="NavBar-companyLogo" onClick={onHomeClick}>
                                <img src={hallsLogo} alt="Company Logo" />
                            </button>
                            <div className="hidden md:flex items-center space-x-4">
                                <button onClick={onAboutClick} className="text-gray-800 hover:text-blue-600">About</button>
                                <button onClick={onPartnersClick} className="text-gray-800 hover:text-blue-600">Partners</button>
                                <button onClick={onTestimonialsClick} className="text-gray-800 hover:text-blue-600">Testimonials</button>
                                <button onClick={onServicesClick} className="text-gray-800 hover:text-blue-600">Services</button>
                                <button onClick={onContactClick} className="bg-blue-600 text-white px-4 py-2 rounded-md">Contact</button>
                            </div>
                            <div className="md:hidden flex items-center">
                                <button className="text-gray-800 p-3 focus:outline-none relative justify-self-center flex flex-col gap-2 cursor-pointer stroke-black stroke-2" onClick={()=>setMenuOpen(!menuOpen)}>
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                        <motion.path initial={path1Variants.closed} animate={path1Controls} transition={{duration: 0.2}} />
                                        <motion.path initial={path2Variants.closed} animate={path2Controls} transition={{duration: 0.2}} />
                                        <motion.path initial={path3Variants.closed} animate={path3Controls} transition={{duration: 0.2}} />
                                    </svg>
                                    {/*<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                                    </svg>*/}
                                </button>
                            </div>
                        </div>
                        <AnimatePresence>
                            {menuOpen &&
                            <motion.div
                                className="NavBar-navMenu top-20 -right-0 w-full border border-gray-400 rounded-md bg-neutral-50 p-8 flex flex-col origin-top"
                                initial={{ scaleY: 0, opacity: 0 }}
                                animate={{ scaleY: 1, opacity: 1 }}
                                exit={{ scaleY: 0, opacity: 0 }}
                                transition={{ duration: 0.5, type: "spring" }}
                            >
                                <div className="md:hidden flex flex-col gap-2 items-start">
                                    <button onClick={() => {onAboutClick(); setMenuOpen(false);}} className="text-gray-800 hover:text-blue-600">About</button>
                                    <button onClick={() => {onPartnersClick(); setMenuOpen(false);}} className="text-gray-800 hover:text-blue-600">Partners</button>
                                    <button onClick={() => {onTestimonialsClick(); setMenuOpen(false);}} className="text-gray-800 hover:text-blue-600">Testimonials</button>
                                    <button onClick={() => {onServicesClick(); setMenuOpen(false);}} className="text-gray-800 hover:text-blue-600">Services</button>
                                    <button onClick={() => {onContactClick(); setMenuOpen(false);}} className="bg-blue-600 text-white px-4 py-2 rounded-md">Contact</button>
                                </div>
                            </motion.div>}
                        </AnimatePresence>
                    </nav>
                </div>
            </motion.header>
            {menuOpen &&
                <div className="modal-overlay absolute w-full h-full bg-black opacity-80 z-20"></div>
            }
        </React.Fragment>
    );
};

export default NavBar;
