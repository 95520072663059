import AnimatedCounter from '../AnimatedCounter/AnimatedCounter.tsx';
import './Stat.css';

const Stat = ({num, xtraChar, desc}) => {
  return (
    <div className="Stat">
      <div className="Stat-value text-4xl font-bold font-mono mt-4 ml-4 mr-4 mb-2">
        <AnimatedCounter from={0} to={num} duration={2} />{xtraChar ? xtraChar : ''}
      </div>
      <div className="Stat-label">{desc}</div>
    </div>
  );
};

export default Stat;
