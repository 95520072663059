import { useState, useEffect } from "react";

const useScreenWidth = () => {
  /*const [screenWidth, setScreenWidth] = useState<
    "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | ""
  >("");*/
  const [screenWidth, setScreenWidth] = useState("");

  useEffect(() => {
    const handleResizeWidth = () => {
      if (window.innerWidth < 640) {
        setScreenWidth("xs");
      } else if (window.innerWidth >= 640 && window.innerWidth < 768) {
        setScreenWidth("sm");
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setScreenWidth("md");
      } else if (window.innerWidth >= 1024 && window.innerWidth < 1280) {
        setScreenWidth("lg");
      } else if (window.innerWidth >= 1280 && window.innerWidth < 1536) {
        setScreenWidth("xl");
      } else if (window.innerWidth >= 1536) {
        setScreenWidth("2xl");
      }
    };

    window.addEventListener("resize", handleResizeWidth);
    handleResizeWidth();

    return () => window.removeEventListener("resize", handleResizeWidth);
  }, []);

  return screenWidth;
};

export default useScreenWidth;
