import { motion } from 'motion/react';
import Stat from '../Stat/Stat.js';
import './Stats.css';

const Stats = () => {
  const stats = [{
    fromX: -200,
    num: 50,
    xtraChar: "+",
    desc: "Years of Service"
  }, {
    fromX: -100,
    num: 43,
    xtraChar: "k",
    desc: "Projects Completed"
  }, {
    fromX: 100,
    num: 15,
    xtraChar: "k",
    desc: "Clients Served"
  }, {
    fromX: 200,
    num: 4.8,
    desc: "Google Rating"
  }];

  return (
    <div className="Stats">
      {stats.map((stat, index) => (
        <motion.div
          key={stat + '_' + index}
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [stat.fromX, 0],
            transition: {
              delay: 0.1,
              duration: 2,
              ease: "easeOut"
            }
          }}
          viewport={{once: true, amount: 0.6}}
        >
          <Stat num={stat.num} xtraChar={stat.xtraChar} desc={stat.desc} />
        </motion.div>
      ))}
    </div>
  );
};

export default Stats;
